<template>
    <div class="app-overlay">
      <div
        class="app-window"
        :style="{ top: windowTop + 'px', left: windowLeft + 'px' }"
      >
        <!-- Barre de titre -->
        <div
          class="title-bar"
          @mousedown.stop="startDrag"
          @touchstart.stop="startDrag"
        >
          <span>Projets</span>
          <button @click="closeApp">X</button>
        </div>
        <!-- Contenu de l'application -->
        <div class="app-content">
          <!-- Contenu des projets -->
          <h2>Mes Projets</h2>
          <p>Liste de vos projets ici.</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ProjectsApp',
    data() {
      return {
        isDragging: false,
        dragStartX: 0,
        dragStartY: 0,
        windowTop: 0,
        windowLeft: 0,
      };
    },
    methods: {
      closeApp() {
        this.$emit('close');
      },
      startDrag(event) {
        event.preventDefault();
        this.isDragging = true;
        const e = event.type === 'touchstart' ? event.touches[0] : event;
        this.dragStartX = e.clientX - this.windowLeft;
        this.dragStartY = e.clientY - this.windowTop;
        document.addEventListener('mousemove', this.onDrag);
        document.addEventListener('mouseup', this.stopDrag);
        document.addEventListener('touchmove', this.onDrag);
        document.addEventListener('touchend', this.stopDrag);
      },
      onDrag(event) {
        if (this.isDragging) {
          const e = event.type === 'touchmove' ? event.touches[0] : event;
          this.windowLeft = e.clientX - this.dragStartX;
          this.windowTop = e.clientY - this.dragStartY;
        }
      },
      stopDrag() {
        this.isDragging = false;
        document.removeEventListener('mousemove', this.onDrag);
        document.removeEventListener('mouseup', this.stopDrag);
        document.removeEventListener('touchmove', this.onDrag);
        document.removeEventListener('touchend', this.stopDrag);
      },
    },
    mounted() {
      // Centrer la fenêtre de l'application
      const windowWidth = 600;
      const windowHeight = 400;
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      this.windowLeft = (screenWidth - windowWidth) / 2;
      this.windowTop = (screenHeight - windowHeight) / 2;
    },
  };
  </script>
  
  <style scoped>
  /* Styles identiques à CVApp.vue */
  .app-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    pointer-events: none;
  }
  
  .app-window {
    position: absolute;
    width: 600px;
    height: 400px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
  }
  
  .title-bar {
    background-color: #f0f0f0;
    color: black;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: move;
    user-select: none;
  }
  
  .title-bar button {
    background: none;
    border: none;
    color: black;
    cursor: pointer;
  }
  
  .app-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }
  </style>
  