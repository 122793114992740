<template>
  <div class="app-overlay">
    <div
      class="app-window"
      :style="{ top: windowTop + 'px', left: windowLeft + 'px' }"
    >
      <!-- Barre de titre -->
      <div
        class="title-bar"
        @mousedown.stop="startDrag"
        @touchstart.stop="startDrag"
      >
        <span>CV</span>
        <button @click="closeApp">X</button>
      </div>
      <!-- Contenu de l'application -->
      <div class="app-content">
        <!-- Titre principal -->
        <h1 class="text-center">Resume</h1>
        <!-- Contenu en deux colonnes -->
        <div class="row">
          <!-- Colonne de gauche -->
          <div class="col-xs-12 col-sm-7">
            <!-- Section Éducation -->
            <div class="block-title">
              <h2>Éducation</h2>
            </div>
            <div class="timeline">
              <!-- Éléments d'éducation -->
              <div
                class="timeline-item"
                v-for="education in educations"
                :key="education.id"
              >
                <div class="timeline-icon"></div>
                <div class="timeline-content">
                  <div class="timeline-date">
                    <span>{{ education.date }}</span>
                  </div>
                  <h4 class="item-title">{{ education.degree }}</h4>
                  <span class="item-company">{{ education.institution }}</span>
                  <p>{{ education.description }}</p>
                </div>
              </div>
            </div>
            <!-- Section Expérience -->
            <div class="block-title">
              <h2>Expérience</h2>
            </div>
            <div class="timeline">
              <!-- Éléments d'expérience -->
              <div
                class="timeline-item"
                v-for="experience in experiences"
                :key="experience.id"
              >
                <div class="timeline-icon"></div>
                <div class="timeline-content">
                  <div class="timeline-date">
                    <span>{{ experience.date }}</span>
                  </div>
                  <h4 class="item-title">{{ experience.position }}</h4>
                  <span class="item-company">{{ experience.company }}</span>
                  <p>{{ experience.description }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Colonne de droite -->
          <div class="col-xs-12 col-sm-5">
            <!-- Section Compétences -->
            <div class="block-title">
              <h2>Compétences</h2>
            </div>
            <div id="skills" class="skills-info skills-first-style">
              <div
                class="skill-item"
                v-for="skill in skills"
                :key="skill.name"
              >
                <div class="clearfix">
                  <h4>{{ skill.name }}</h4>
                  <div class="skill-value">{{ skill.value }}%</div>
                </div>
                <div class="skill-container">
                  <div
                    class="skill-percentage"
                    :style="{ width: skill.value + '%' }"
                  ></div>
                </div>
              </div>
            </div>
            <!-- Section Certifications -->
            <div class="block-title">
              <h2>Certifications</h2>
            </div>
            <div class="certifications">
              <div
                class="certificate-item"
                v-for="certification in certifications"
                :key="certification.id"
              >
                <div class="certi-logo">
                  <img :src="certification.image" alt="logo" />
                </div>
                <div class="certi-content">
                  <div class="certi-title">
                    <h4>{{ certification.title }}</h4>
                  </div>
                  <div class="certi-id">
                    <span>ID: {{ certification.idNumber }}</span>
                  </div>
                  <div class="certi-date">
                    <span>{{ certification.date }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Section Langues -->
            <div class="block-title">
              <h2>Langues</h2>
            </div>
            <ul class="languages">
              <li v-for="language in languages" :key="language.name">
                <strong>{{ language.name }}:</strong> {{ language.level }}
              </li>
            </ul>
            <!-- Section Outils -->
            <div class="block-title">
              <h2>Outils</h2>
            </div>
            <div class="tools">
              <div
                class="tool-item"
                v-for="tool in tools"
                :key="tool.name"
              >
                <div class="tool-image-container">
                  <img :src="tool.logo" :alt="tool.name" />
                </div>
                <div class="tool-name">{{ tool.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SSTImage from '@/assets/CV_assets/SST.jpg';
import GDPImage from '@/assets/CV_assets/logo-mooc-gdp.png';
// Importation des logos des outils
import Office365Logo from '@/assets/CV_assets/365-Logo.png';
import PowerBILogo from '@/assets/CV_assets/PowerBI.png';
import CentreonLogo from '@/assets/CV_assets/centreon_logo.png';
import MobaXtermLogo from '@/assets/CV_assets/MobaX.jpg';
import WiresharkLogo from '@/assets/CV_assets/Wireshark.png';
import PuTTYLogo from '@/assets/CV_assets/PuttY.png';
import OpenVPNLogo from '@/assets/CV_assets/OpenVPN.png';
import KaliLogo from '@/assets/CV_assets/Kali.png';
import AnsibleLogo from '@/assets/CV_assets/Ansible.png';
import AWSLogo from '@/assets/CV_assets/AWS.png';
import PowerShellLogo from '@/assets/CV_assets/PowerShell.png';
import GitLabLogo from '@/assets/CV_assets/GitLab.png';
import DockerLogo from '@/assets/CV_assets/Docker.png';
import ProxmoxLogo from '@/assets/CV_assets/Proxmox.png';
// Importez d'autres logos ici

export default {
  name: 'CVApp',
  data() {
    return {
      isDragging: false,
      dragStartX: 0,
      dragStartY: 0,
      windowTop: 0,
      windowLeft: 0,
      educations: [
        {
          id: 1,
          date: '2023 - 2026',
          degree: "Diplôme d'Ingénieur",
          institution: 'EFREI Paris',
          description: 'Diplôme Réseau & Sécurité',
        },
        {
          id: 2,
          date: '2022 - 2023',
          degree: 'Licence Professionnelle',
          institution: 'UTEC',
          description:
            'Licence Professionnelle Administration des systèmes, réseaux et sécurité avec Gestion de projet',
        },
        {
          id: 3,
          date: '2020 - 2022',
          degree: 'BTS SIO',
          institution: 'Lycée René Descartes - Champs Sur Marne',
          description:
            'Option SISR (Solutions d’Infrastructure, Systèmes et Réseaux)',
        },
        {
          id: 4,
          date: '2017 - 2020',
          degree: 'BAC Scientifique',
          institution: 'Lycée La Tour des Dames - Rozay-En-Brie',
          description: 'BAC Scientifique',
        },
      ],
      experiences: [
        {
          id: 1,
          date: '2022 - Présent',
          position: 'Alternant Réseaux & Systèmes',
          company: 'PARROT',
          description: 'Réseaux, Monitoring, Scripting, Gestion',
        },
        {
          id: 2,
          date: '2022 - 2022',
          position: 'Stagiaire Support Informatique',
          company: 'PARROT',
          description: 'Support Informatique',
        },
        {
          id: 3,
          date: '2021 - 2021',
          position: 'Stagiaire Support Informatique',
          company: 'PARROT',
          description: 'Support Informatique',
        },
        // Ajoutez d'autres expériences ici
      ],
      skills: [
        { name: 'Cisco / HP', value: 90 },
        { name: 'PowerShell / Bash / Python', value: 80 },
        { name: 'Linux', value: 70 },
        { name: 'Windows', value: 85 },
        // Ajoutez d'autres compétences ici
      ],
      certifications: [
        {
          id: 1,
          image: SSTImage,
          title: "Prévention Sup' - INRS",
          idNumber: '256456',
          date: 'Octobre 2024',
        },
        {
          id: 2,
          image: GDPImage,
          title: 'MOOC Gestion de Projet',
          idNumber: '1770c40c5d754091b50883a1011626c9',
          date: 'Avril 2024',
        },
        // Ajoutez d'autres certifications ici
      ],
      languages: [
        { name: 'Français', level: 'Langue maternelle' },
        { name: 'Anglais', level: 'Compétence professionnelle' },
        { name: 'Italien', level: 'Notions' },
        { name: 'Allemand', level: 'Notions' },
      ],
      tools: [
        { name: 'Office365', logo: Office365Logo },
        { name: 'PowerBI', logo: PowerBILogo },
        { name: 'Centreon', logo: CentreonLogo },
        { name: 'MobaXterm', logo: MobaXtermLogo },
        { name: 'Wireshark', logo: WiresharkLogo },
        { name: 'PuTTY', logo: PuTTYLogo },
        { name: 'OpenVPN', logo: OpenVPNLogo },
        { name: 'Kali', logo: KaliLogo },
        { name: 'Ansible', logo: AnsibleLogo },
        { name: 'AWS', logo: AWSLogo },
        { name: 'PowerShell', logo: PowerShellLogo },
        { name: 'GitLab', logo: GitLabLogo },
        { name: 'Docker', logo: DockerLogo },
        { name: 'Proxmox', logo: ProxmoxLogo },
        // Ajoutez d'autres outils ici, n'oubliez pas d'importer leurs logos
      ],
    };
  },
  methods: {
    closeApp() {
      this.$emit('close');
    },
    startDrag(event) {
      event.preventDefault();
      this.isDragging = true;
      const e = event.type === 'touchstart' ? event.touches[0] : event;
      this.dragStartX = e.clientX - this.windowLeft;
      this.dragStartY = e.clientY - this.windowTop;
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);
      document.addEventListener('touchmove', this.onDrag);
      document.addEventListener('touchend', this.stopDrag);
    },
    onDrag(event) {
      if (this.isDragging) {
        const e = event.type === 'touchmove' ? event.touches[0] : event;
        this.windowLeft = e.clientX - this.dragStartX;
        this.windowTop = e.clientY - this.dragStartY;
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.stopDrag);
      document.removeEventListener('touchmove', this.onDrag);
      document.removeEventListener('touchend', this.stopDrag);
    },
  },
  mounted() {
    const windowWidth = 1200; // Taille augmentée
    const windowHeight = 700; // Taille augmentée
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    this.windowLeft = (screenWidth - windowWidth) / 2;
    this.windowTop = (screenHeight - windowHeight) / 2;
  },
};
</script>

<style scoped>
/* Styles de base de l'application */
.app-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  pointer-events: none;
}

.app-window {
  position: absolute;
  width: 1200px;
  height: 700px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
}

.title-bar {
  background-color: #f0f0f0;
  color: black;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: move;
  user-select: none;
}

.title-bar button {
  background: none;
  border: none;
  color: black;
  cursor: pointer;
}

.app-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

/* Styles spécifiques au CV */
.block-title h2 {
  font-size: 24px;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
}

.block-title h2::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 50px;
  height: 2px;
  background-color: grey;
}

/* Timeline styles */
.timeline {
  position: relative;
  margin-left: 30px;
}

.timeline::before {
  content: '';
  position: absolute;
  left: 15px;
  top: 0;
  width: 2px;
  height: 100%;
  background: grey;
}

.timeline-item {
  position: relative;
  padding-left: 40px;
  margin-bottom: 30px;
}

.timeline-item:last-child {
  margin-bottom: 0;
}

.timeline-icon {
  position: absolute;
  left: 7px;
  top: 5px;
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 2px solid grey;
  border-radius: 50%;
}

.timeline-content {
  position: relative;
  margin-left: 0;
  margin-right: 40px;
}

.timeline-date {
  position: relative;
  margin-bottom: 5px;
  left: 0;
  top: 0;
}

.timeline-date span {
  display: inline-block;
  padding: 2px 6px;
  border: 1px solid grey;
  border-radius: 4px;
  white-space: nowrap;
  font-size: smaller;
  background-color: transparent;
}

.timeline-item::after {
  content: '';
  position: absolute;
  left: 23px;
  top: 13px;
  width: 17px;
  height: 2px;
  background: grey;
}

.item-title {
  font-size: 18px;
  margin-bottom: 5px;
}

.item-company {
  display: block;
  font-style: italic;
  margin-bottom: 10px;
}

/* Styles pour les compétences */
.skills-info .skill-item {
  margin-bottom: 15px;
}

.skill-container {
  background: #f0f0f0;
  height: 8px;
  position: relative;
  border-radius: 4px; /* Arrondir les bords */
}

.skill-percentage {
  background-color: #007bff; /* Bleu plus prononcé */
  height: 100%;
  border-radius: 4px; /* Arrondir les bords */
}

.skill-value {
  float: right;
}

/* Styles pour les certifications */
.certificate-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid grey; /* Gris contour seulement */
  padding: 10px;
  background-color: transparent; /* Pas de couleur de fond */
  border-radius: 4px; /* Arrondir les coins */
}

.certi-logo img {
  width: 100px;
  height: auto;
  margin-right: 15px;
}

.certi-content .certi-title h4 {
  margin: 0 0 5px 0;
}

.certi-id,
.certi-date {
  font-size: 14px;
  color: #777;
}

/* Styles pour les langues */
.languages {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
}

.languages li {
  margin-bottom: 10px;
  font-size: 16px;
}

/* Styles pour les outils */
.tools {
  display: flex;
  flex-wrap: wrap;
}

.tool-item {
  width: 60px;
  margin: 5px;
  position: relative;
  transition: transform 0.3s ease; /* Animation douce */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tool-image-container {
  width: 100%;
  height: 60px;
  overflow: hidden;
}

.tool-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.tool-name {
  opacity: 0;
  transition: opacity 0.3s ease;
  text-align: center;
  font-size: 12px;
  color: #333;
  margin-top: 5px;
}

.tool-item:hover .tool-name {
  opacity: 1;
}

.tool-item:hover {
  transform: scale(1.1); /* Agrandit légèrement le logo */
}
</style>
