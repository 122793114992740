<template>
  <div class="taskbar">
    <!-- Bouton Démarrer -->
    <div class="start-button" @click="toggleStartMenu">
      <div class="circle-grid">
        <span class="circle"></span>
        <span class="circle"></span>
        <span class="circle"></span>
        <span class="circle"></span>
      </div>
    </div>

    <!-- Menu Démarrer -->
    <div v-if="isStartMenuOpen" class="start-menu">
      <div class="app-grid">
        <!-- Icône du terminal -->
        <div class="app-icon" @click="emitOpenTerminal">
          <font-awesome-icon icon="terminal" size="2x" />
          <span>Terminal</span>
        </div>
        <!-- Icône du shutdown -->
        <div class="app-icon" @click="emitOpenShutdownModal">
          <font-awesome-icon icon="power-off" size="2x" />
          <span>Éteindre</span>
        </div>
        <!-- Vous pouvez ajouter d'autres icônes ici -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TaskBar',
  data() {
    return {
      isStartMenuOpen: false,
    };
  },
  methods: {
    toggleStartMenu() {
      this.isStartMenuOpen = !this.isStartMenuOpen;
    },
    emitOpenTerminal() {
      this.$emit('open-terminal');
      this.isStartMenuOpen = false; // Fermer le menu Démarrer
    },
    emitOpenShutdownModal() {
      this.$emit('open-shutdown-modal');
      this.isStartMenuOpen = false; // Fermer le menu Démarrer
    },
  },
};
</script>

<style scoped>
.taskbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  padding: 0 20px;
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.start-button {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 15px;
}

.start-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.circle-grid {
  display: grid;
  grid-template-columns: repeat(2, 15px);
  grid-template-rows: repeat(2, 15px);
  gap: 5px;
}

.circle {
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
}

/* Styles pour le menu Démarrer */
.start-menu {
  position: fixed;
  bottom: 60px;
  left: 20px;
  width: 300px;
  max-height: 400px;
  background-color: rgba(30, 30, 30, 0.95);
  border-radius: 10px;
  padding: 20px;
  overflow-y: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.app-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.app-icon {
  width: 60px;
  text-align: center;
  color: white;
  cursor: pointer;
}

.app-icon span {
  display: block;
  margin-top: 5px;
  font-size: 12px;
}

.app-icon:hover {
  color: #1e90ff;
}
</style>
