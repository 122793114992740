<template>
    <div class="menubar">
      <div class="menu-left">
        <!-- Menu Apple, Fichier, Édition, etc. -->
      </div>
      <div class="menu-right">
        <!-- Icônes de statut : Wi-Fi, batterie, etc. -->
        <span>{{ currentDateTime }}</span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentDateTime: ''
      };
    },
    created() {
      this.updateDateTime();
      setInterval(this.updateDateTime, 1000); // Mettre à jour chaque seconde
    },
    methods: {
      updateDateTime() {
        const now = new Date();
        const optionsDate = { weekday: 'long', day: 'numeric', month: 'short' };
        const optionsTime = { hour: '2-digit', minute: '2-digit'};
        const date = now.toLocaleDateString('fr-FR', optionsDate);
        const time = now.toLocaleTimeString('fr-FR', optionsTime);
        this.currentDateTime = `${date} ${time}`;
      }
    }
  };
  </script>
  
  <style scoped>
    .menubar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 25px;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    backdrop-filter: blur(10px);
    z-index: 1000;
    }

  
  .menu-left,
  .menu-right {
    display: flex;
    align-items: center;
  }
  
  .menu-right span {
    margin-left: 10px;
    margin-right: 1em;
    font-weight: bold;
  }
  </style>
  