import { createApp } from 'vue';
import App from './App.vue';

// Importations pour Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTerminal, faIdCard, faCalendarCheck, faBook, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Import Bootstrap et BootstrapVue CSS
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
// Import BootstrapVue 3

import BootstrapVue3 from 'bootstrap-vue-3';

// Ajouter les icônes à la bibliothèque
library.add(faTerminal, faIdCard, faCalendarCheck, faBook, faPowerOff);

// Créer l'application Vue
const app = createApp(App);

// Enregistrer le composant FontAwesomeIcon globalement
app.component('font-awesome-icon', FontAwesomeIcon);

// Utiliser BootstrapVue 3
app.use(BootstrapVue3);

// Monter l'application
app.mount('#app');
