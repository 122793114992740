<template>
  <div class="desktop">
    <!-- Icônes du bureau -->
    <div class="desktop-icons">
      <!-- Application CV -->
      <div class="desktop-icon" @dblclick="openCVApp">
        <font-awesome-icon icon="id-card" size="3x" />
        <span>CV</span>
      </div>
      <!-- Application Projets -->
      <div class="desktop-icon" @dblclick="openProjectsApp">
        <font-awesome-icon icon="calendar-check" size="3x" />
        <span>Projets</span>
      </div>
      <!-- Application Documentation -->
      <div class="desktop-icon" @dblclick="openDocumentation">
        <font-awesome-icon icon="book" size="3x" />
        <span>Documentation</span>
      </div>
    </div>
    <!-- Applications -->
    <CVApp v-if="isCVAppOpen" @close="closeCVApp" />
    <ProjectsApp v-if="isProjectsAppOpen" @close="closeProjectsApp" />
  </div>
</template>

<script>
import CVApp from './CVApp.vue';
import ProjectsApp from './ProjectsApp.vue';

export default {
  name: 'DesktopVue',
  components: {
    CVApp,
    ProjectsApp,
  },
  data() {
    return {
      isCVAppOpen: false,
      isProjectsAppOpen: false,
    };
  },
  methods: {
    openCVApp() {
      this.isCVAppOpen = true;
    },
    closeCVApp() {
      this.isCVAppOpen = false;
    },
    openProjectsApp() {
      this.isProjectsAppOpen = true;
    },
    closeProjectsApp() {
      this.isProjectsAppOpen = false;
    },
    openDocumentation() {
      window.open('https://www.google.com', '_blank');
    },
  },
};
</script>

<style scoped>
.desktop {
  width: 100vw;
  height: 100vh;
  background-image: url('@/assets/background.svg');
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}

/* Styles des icônes du bureau */
.desktop-icons {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligner les icônes à gauche */
  padding: 20px;
}

.desktop-icon {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centrer le contenu à l'intérieur de l'icône */
  width: 100px; /* Définir une largeur fixe pour toutes les icônes */
  margin-bottom: 20px;
  color: white;
  cursor: pointer;
  user-select: none;
}

.desktop-icon span {
  display: block;
  margin-top: 5px;
  white-space: nowrap; /* Empêcher le retour à la ligne du texte */
  text-align: center;
}

.desktop-icon:hover {
  color: #1e90ff;
}
</style>
