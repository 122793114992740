<template>
  <div id="app">
    <Menubar />
    <Desktop />
    <Taskbar
      @open-terminal="openTerminal"
      @open-shutdown-modal="showShutdownModal = true"
    />
    <TerminalApp
      v-if="isTerminalOpen"
      @close="closeTerminal"
      @explosion="triggerExplosion"
    />
    <Fireworks v-if="showFireworks" @finished="showFireworks = false" />

    <!-- Modale de confirmation d'arrêt -->
    <b-modal
      id="shutdown-modal"
      v-model="showShutdownModal"
      title="Confirmation"
      centered
      hide-footer
    >
      <p>Voulez-vous éteindre Lilian OS ?</p>
      <div class="d-flex justify-content-end">
        <b-button variant="secondary" @click="showShutdownModal = false">
          Annuler
        </b-button>
        <b-button variant="danger" @click="confirmShutdown" class="ml-2">
          Confirmer
        </b-button>
      </div>
    </b-modal>

    <!-- Écran de shutdown avec logo de chargement -->
    <div v-if="isShuttingDown" class="shutdown-screen">
      <div class="shutdown-content">
        <p>Lilian OS is shutting down</p>
        <div class="loader"></div>
      </div>
    </div>

    <!-- Nouvelle modale après l'échec du shutdown -->
    <b-modal
      id="shutdown-failed-modal"
      v-model="showShutdownFailedModal"
      title="Échec de l'extinction"
      centered
      hide-footer
    >
      <p>L'extinction de Lilian OS n'a pas pu avoir lieu car il ne faut pas quitter le site de Lilian</p>
      <div class="d-flex justify-content-end">
        <b-button variant="primary" @click="showShutdownFailedModal = false">
          OK
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Menubar from './components/MenuBar.vue';
import Desktop from './components/DesktopVue.vue';
import Taskbar from './components/TaskBar.vue';
import TerminalApp from './components/TerminalApp.vue';
import Fireworks from './components/FireworksVue.vue';

export default {
  name: 'App',
  components: {
    Menubar,
    Desktop,
    Taskbar,
    TerminalApp,
    Fireworks,
  },
  data() {
    return {
      isTerminalOpen: false,
      showFireworks: false,
      showShutdownModal: false,
      isShuttingDown: false,
      showShutdownFailedModal: false, // Nouvelle variable pour la deuxième modale
    };
  },
  methods: {
    openTerminal() {
      this.isTerminalOpen = true;
    },
    closeTerminal() {
      this.isTerminalOpen = false;
    },
    triggerExplosion() {
      this.showFireworks = true;
    },
    confirmShutdown() {
      this.showShutdownModal = false;
      this.isShuttingDown = true;
      setTimeout(() => {
        this.isShuttingDown = false;
        this.showShutdownFailedModal = true; // Afficher la nouvelle modale
      }, 2000);
    },
  },
};
</script>

<style>
body,
html,
#app {
  margin: 0;
  padding: 0;
}

/* Styles pour l'écran de shutdown */
.shutdown-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shutdown-content {
  text-align: center;
}

.shutdown-content p {
  color: white;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Styles pour le logo de chargement */
.loader {
  border: 8px solid #f3f3f3; /* Couleur de fond */
  border-top: 8px solid #3498db; /* Couleur de la bande */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: 0 auto; /* Centrer le loader */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
