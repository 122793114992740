<template>
  <div class="terminal-overlay">
    <div
      class="terminal-window"
      :style="{ top: windowTop + 'px', left: windowLeft + 'px' }"
    >
      <!-- Barre de titre -->
      <div
        class="title-bar"
        @mousedown.stop="startDrag"
        @touchstart.stop="startDrag"
      >
        <span>Terminal</span>
        <button @click="closeTerminal">X</button>
      </div>
      <!-- Contenu du terminal -->
      <div class="terminal-content" ref="terminalContent">
        <div v-for="(line, index) in outputLines" :key="index">
          <span v-html="line"></span>
        </div>
        <div class="input-line">
          <span>{{ prompt }}</span>
          <input
            type="text"
            v-model="currentInput"
            @keyup.enter="handleCommand"
            ref="inputField"
            autocomplete="off"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TerminalApp',
  data() {
    return {
      prompt: '$ ',
      currentInput: '',
      outputLines: [],
      isDragging: false,
      dragStartX: 0,
      dragStartY: 0,
      windowTop: 0,
      windowLeft: 0,
    };
  },
  methods: {
    closeTerminal() {
      this.$emit('close');
    },
    handleCommand() {
      const input = this.currentInput.trim();
      if (input !== '') {
        this.outputLines.push(this.prompt + input);
        this.processCommand(input);
      }
      this.currentInput = '';
      this.$nextTick(() => {
        // Faire défiler vers le bas
        const terminalContent = this.$refs.terminalContent;
        if (terminalContent) {
          terminalContent.scrollTop = terminalContent.scrollHeight;
        }
      });
    },
    processCommand(cmd) {
      const args = cmd.split(' ');
      const command = args[0];
      switch (command) {
        case 'help':
          this.outputLines.push(
            'Commandes disponibles: <br/>- help<br/>- echo [texte]<br/>- clear<br/>- ls<br/>- date<br/>- whoami'
          );
          break;
        case 'echo':
          this.outputLines.push(args.slice(1).join(' '));
          break;
        case 'clear':
          this.outputLines = [];
          break;
        case 'ls':
          this.outputLines.push('Documents  Images  Téléchargements  Musique');
          break;
        case 'date':
          this.outputLines.push(new Date().toString());
          break;
        case 'whoami':
          this.outputLines.push('Utilisateur');
          break;
        case 'Explosion-All-Secret':
          this.outputLines.push('Activation du mode explosion...');
          // Émettre un événement pour déclencher les feux d'artifice
          this.$emit('explosion');
          // Fermer le terminal
          this.closeTerminal();
          break;
        default:
          this.outputLines.push(`Commande non reconnue: ${cmd}`);
          break;
      }
    },
    startDrag(event) {
      event.preventDefault();
      this.isDragging = true;
      const e = event.type === 'touchstart' ? event.touches[0] : event;
      this.dragStartX = e.clientX - this.windowLeft;
      this.dragStartY = e.clientY - this.windowTop;
      // Écouteurs pour le déplacement
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);
      document.addEventListener('touchmove', this.onDrag);
      document.addEventListener('touchend', this.stopDrag);
    },
    onDrag(event) {
      if (this.isDragging) {
        const e = event.type === 'touchmove' ? event.touches[0] : event;
        this.windowLeft = e.clientX - this.dragStartX;
        this.windowTop = e.clientY - this.dragStartY;
      }
    },
    stopDrag() {
      this.isDragging = false;
      // Supprimer les écouteurs
      document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.stopDrag);
      document.removeEventListener('touchmove', this.onDrag);
      document.removeEventListener('touchend', this.stopDrag);
    },
  },
  mounted() {
    // Centrer la fenêtre du terminal
    const windowWidth = 600; // Largeur de la fenêtre du terminal
    const windowHeight = 400; // Hauteur de la fenêtre du terminal
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    this.windowLeft = (screenWidth - windowWidth) / 2;
    this.windowTop = (screenHeight - windowHeight) / 2;

    // Focus sur le champ de saisie
    this.$refs.inputField.focus();
  },
  updated() {
    // Garder le focus sur le champ de saisie
    this.$refs.inputField.focus();
  },
};
</script>

<style scoped>
/* Votre style existant */
.terminal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Fond transparent pour voir le bureau */
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1001;
}

.terminal-window {
  position: absolute;
  width: 600px;
  height: 400px;
  background-color: #1e1e1e;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.title-bar {
  background-color: #2d2d2d;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: move;
  user-select: none; /* Empêcher la sélection de texte */
}

.title-bar button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.terminal-content {
  flex: 1;
  padding: 10px;
  color: #d4d4d4;
  font-family: 'Courier New', Courier, monospace;
  overflow-y: auto;
}

.terminal-content div {
  white-space: pre-wrap;
}

.input-line {
  display: flex;
}

.input-line span {
  color: #1e90ff;
}

.input-line input {
  background: none;
  border: none;
  color: #d4d4d4;
  outline: none;
  flex: 1;
  font-family: 'Courier New', Courier, monospace;
}
</style>
