<template>
    <div class="fireworks-container" ref="container"></div>
  </template>
  
  <script>
  import { Fireworks } from 'fireworks-js';
  
  export default {
    name: 'FireworksVue',
    data() {
      return {
        fireworks: null,
      };
    },
    mounted() {
      const container = this.$refs.container;
  
      // Vérifier que le conteneur a des dimensions
      if (container) {
        // Initialiser les feux d'artifice avec les options par défaut
        this.fireworks = new Fireworks(container, {
          autoresize: true,
          opacity: 0.5,
          acceleration: 1.5,
          friction: 0.97,
          gravity: 3,
          particles: 50,
          traceLength: 3,
          traceSpeed: 10,
          explosion: 10,
          intensity: 60,
          flickering: 50,
          lineWidth: {
            trace: 1,
            explosion: 2,
          },
          hue: {
            min: 0,
            max: 360,
          },
          delay: {
            min: 30,
            max: 60,
          },
          rocketsPoint: {
            min: 50,
            max: 50,
          },
        });
  
        this.fireworks.start();
  
        // Arrêter les feux d'artifice après 5 secondes
        setTimeout(() => {
          this.fireworks.stop();
          // Émettre un événement pour supprimer le composant
          this.$emit('finished');
        }, 5000);
      }
    },
    beforeUnmount() {
      if (this.fireworks) {
        this.fireworks.stop();
      }
    },
  };
  </script>
  
  <style scoped>
  .fireworks-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    pointer-events: none; /* Pour ne pas bloquer les interactions */
  }
  </style>
  